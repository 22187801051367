import { useSelect } from 'Hooks';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isGettingCredentials, isLoggedIn } = useSelect(({ credentials }) => ({
    isGettingCredentials: credentials.isGettingCredentialsSelector,
    isLoggedIn: credentials.isLoggedInSelector,
  }));

  if (!isGettingCredentials && !isLoggedIn) return <Redirect to="/login" />;
  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default PrivateRoute;
