import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { IntlProvider } from 'Providers/IntlProvider';
import { version } from '../../../package.json';

import Routes from '../Routes/Routes';
import { GlobalStyle, Sanitize, Typography } from 'Components/Styles';
import store from 'Store';
// import { cryptobadgeIframeId } from 'Utils/postMessage';
import { HelmetProvider } from 'react-helmet-async';
// import { CRYPTO_BADGE_URL } from 'Utils/constants';
// import { receiveMessage } from 'Utils/postMessage';

store.dispatch.credentials.getCurrentSessionAsync();
store.dispatch.credentials.getCurrentCredentialsAsync();

function App() {
  useEffect(() => {
    // window.addEventListener('message', receiveMessage, false);
    console.log('CANpass version: ', version);
    // return () => {
    //   window.removeEventListener('message', receiveMessage, false);
    // };
  }, []);

  return (
    <HelmetProvider>
      <Sanitize />
      <Typography />
      <GlobalStyle />
      <Provider store={store}>
        <IntlProvider>
          <Routes />
          {/* <iframe
            title={cryptobadgeIframeId}
            id={cryptobadgeIframeId}
            style={{ display: 'none' }}
            src={`${CRYPTO_BADGE_URL}/iframe`}
          >
            <p>Your browser does not support iframes.</p>
          </iframe> */}
        </IntlProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
