import NodeRSA from 'node-rsa';

export const isTestingEnv =
  !process.env.REACT_APP_ENV ||
  ['dev', 'test'].includes(process.env.REACT_APP_ENV);

export const rsa512Encrypt = async (text, pkey) => {
  const key = new NodeRSA({ b: 512 });

  const rsaKey = key.importKey(pkey, 'pkcs1-public');
  const encrypted = rsaKey.encrypt(text, 'base64');

  return encrypted;
};
