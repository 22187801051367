import {
  getApplicationById,
  createApplication,
  getApplications,
  updateApplication,
} from '../../Services/application';
import { get } from 'lodash';
import { push } from 'connected-react-router';

const applicationInit = {
  currentApplication: {},
  applications: [],
};

export const application = {
  state: applicationInit, // initial state
  reducers: {
    updateCurrentApplication(state, payload) {
      return { ...state, currentApplication: payload };
    },
    updateListApplications(state, payload) {
      return { ...state, applications: payload };
    },
    clear() {
      return { applicationInit };
    },
  },
  effects: dispatch => ({
    async createApp(action) {
      try {
        const { applicationName, applicationUrl } = action;
        const data = await createApplication(applicationName, applicationUrl);
        dispatch.notification.openAsync('Create application successfully!');
        dispatch(push(`/?appId=${data.application_id}`));
      } catch (e) {
        dispatch.notification.openAsync(
          get(e.response, 'data.error_description'),
        );
      }
    },
    async getApplicationsAsync() {
      try {
        const data = await getApplications();
        dispatch.application.updateListApplications(data);
      } catch (e) {
        dispatch.notification.openAsync(
          get(e.response, 'data.error_description'),
        );
      }
    },
    async fetchCurrentApplication(applicationId) {
      try {
        const data = await getApplicationById(applicationId);
        dispatch.application.updateCurrentApplication(data);
      } catch (e) {
        dispatch.notification.openAsync(
          get(e.response, 'data.error_description'),
        );
        console.error(e);
      }
    },
    async updateApplicationInfo(action) {
      try {
        const {
          application_id,
          application_name,
          application_url,
          is_consent_display,
        } = action;
        const data = await updateApplication(
          application_id,
          application_name,
          application_url,
          is_consent_display,
        );

        dispatch.application.updateCurrentApplication(data);
        dispatch.notification.openAsync('Update client successfully');
      } catch (e) {
        console.log(e);
        dispatch.notification.openAsync(
          get(e.response, 'data.error_description'),
        );
        console.error(e);
      }
    },
    async clearStore() {
      dispatch.application.clear();
    },
  }),
  selectors: slice => ({
    currentApplicationSelector() {
      return slice(state => get(state, 'currentApplication'));
    },
    applicationsSelector() {
      return slice(state => get(state, 'applications'));
    },
  }),
};
