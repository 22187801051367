import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import logo from 'Assets/Images/logo.svg';
import arrowDown from 'Assets/Images/drop-down-arrow.svg';
import { Link } from 'react-router-dom';
import { colors, media } from 'Theme/constants';
import { useRematchDispatch, useSelect } from '../../Hooks';
import { Avatar } from '../../Components/Avatar';
import ModalWrapper from '../ModalWrapper';
import ApplicationsModal from '../ApplicationsModal';
import useOnClickOutside from '../../Hooks/useOnClickOutside';
import { withRouter } from 'react-router';

const HeaderStyled = styled.header`
  background-color: ${colors.blue};
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0 0.4rem 1.4rem 0;
  position: fixed;
  z-index: 101;
  width: 100%;
  @media (min-width: ${media.desktop}) {
    height: 6rem;
    padding: 0 2rem;
  }
`;

const LeftNav = styled.div`
  .cp-logo {
    width: 3.5rem;
    height: 3.5rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.2rem;
    img {
      width: 100%;
      box-shadow: rgba(42, 42, 42, 0.11) 0.1rem 0.5rem 0.5rem 0;
      border-radius: 50%;
    }
  }
  .cp-btn-dropdown {
    display: inline-block;
    vertical-align: middle;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    position: relative;
    padding: 1.2rem 0.8rem;
    font-size: 1.4rem;
    color: white;
    span {
      display: flex;
      justify-content: center;
      img {
        width: 1.2rem;
        margin-left: 0.4rem;
        transform: translateY(0.2rem);
      }
    }
  }

  @media (min-width: ${media.desktop}) {
    .cp-logo {
      width: 5rem;
      height: 5rem;
    }
  }
`;

const RightNav = styled.div`
  display: flex;
  align-items: center;
  .cp-avatar {
    width: 3rem;
    height: 3rem;
    margin-right: 0.8rem;
    img {
      border-radius: 50%;
      width: 100%;
    }
  }
  @media (min-width: ${media.desktop}) {
  }
`;

const ButtonStyled = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  border: 0;
  display: block;
  position: relative;
  cursor: pointer;
  border-radius: 0.3rem;
  background-color: transparent;
  transition: background-color 300ms ease-in-out;
  span {
    width: 0.3rem;
    height: 0.3rem;
    margin: 0 auto;
    display: block;
    border-radius: 50%;
    position: relative;
    background-color: ${colors.white};
    &:before {
      content: '';
      width: 0.3rem;
      height: 0.3rem;
      display: block;
      border-radius: 50%;
      position: absolute;
      background-color: ${colors.white};
      top: -0.5rem;
    }
    &:after {
      content: '';
      width: 0.3rem;
      height: 0.3rem;
      display: block;
      border-radius: 50%;
      position: absolute;
      background-color: ${colors.white};
      bottom: -0.5rem;
    }
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${colors.white}33;
    transition: background-color 300ms ease-in-out;
  }

  @media (min-width: ${media.desktop}) {
    width: 30px;
    height: 30px;
    span {
      width: 4px;
      height: 4px;
      &:before {
        width: 4px;
        height: 4px;
        top: -7px;
      }
      &:after {
        width: 4px;
        height: 4px;
        bottom: -7px;
      }
    }
  }
`;

const Dropdown = styled.div`
  position: relative;
  ul {
    position: absolute;
    right: 0;
    bottom: -1rem;
    background-color: ${colors.white};
    transform: translate(0, 100%);
    border-radius: 0.2rem;
    box-shadow: rgba(0, 0, 0, 0.21) 0 5px 25px 0;
    li {
      button {
        padding: 0.8rem;
        display: block;
        color: ${colors.black};
        word-break: keep-all;
        background-color: transparent;
        border: 0;
      }
    }
  }
`;

const Header = props => {
  const {
    logOutAsync,
    getApplicationsAsync,
    fetchCurrentApplication,
  } = useRematchDispatch(({ credentials, application }) => ({
    logOutAsync: credentials.logOutAsync,
    getApplicationsAsync: application.getApplicationsAsync,
    fetchCurrentApplication: application.fetchCurrentApplication,
  }));

  const [openNav, setOpenNav] = useState(false);

  const { currentApplication, userAttributes, isLoggedIn } = useSelect(
    ({ credentials, application }) => ({
      userAttributes: credentials.userAttributesSelector,
      currentApplication: application.currentApplicationSelector,
      isLoggedIn: credentials.isLoggedInSelector,
    }),
  );
  const [toggleModal, setToggleModal] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setToggleModal(false));

  const refNav = useRef();
  useOnClickOutside(refNav, () => setOpenNav(false));

  const { location } = props;
  const searchParams = new URLSearchParams(location.search);
  const appId = searchParams.get('appId');

  function openAppsModal() {
    setToggleModal(true);
  }

  function toggleNav() {
    setOpenNav(!openNav);
  }

  useEffect(() => {
    if (isLoggedIn) {
      if (appId) {
        fetchCurrentApplication(appId);
      }
      getApplicationsAsync();
    }
  }, [appId, fetchCurrentApplication, getApplicationsAsync, isLoggedIn]);

  const existApplication =
    currentApplication && currentApplication.application_name;
  return (
    <React.Fragment>
      <HeaderStyled>
        <LeftNav>
          <Link className="cp-logo" to={`/${location.search}`}>
            <img src={logo} alt="logo" />
          </Link>
          <button className="cp-btn-dropdown" onClick={() => openAppsModal()}>
            <span>
              {existApplication
                ? currentApplication.application_name
                : 'Select a app'}
              <img src={arrowDown} alt="icon" />
            </span>
          </button>
        </LeftNav>
        <RightNav>
          <div className="cp-avatar">
            <Avatar src={userAttributes.imageUrl} />
          </div>
          <Dropdown ref={refNav}>
            <ButtonStyled type="button" onClick={() => toggleNav()}>
              <span />
            </ButtonStyled>
            {openNav && (
              <ul>
                <li>
                  <button onClick={logOutAsync}>Logout</button>
                </li>
              </ul>
            )}
          </Dropdown>
        </RightNav>
      </HeaderStyled>

      {toggleModal && (
        <ModalWrapper>
          <div ref={ref}>
            <ApplicationsModal handleClose={() => setToggleModal(false)} />
          </div>
        </ModalWrapper>
      )}
    </React.Fragment>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default withRouter(Header);
