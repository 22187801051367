import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { colors } from '../../Theme/constants';

const ButtonLinkStyled = styled.div`
  a {
    display: inline-block;
    background-color: ${props => props.backgroundColor};
    border: 0.1rem solid ${props => props.backgroundColor};
    border-radius: ${props => props.borderRadius}rem;
    color: ${props => props.color};
    height: ${props => props.height}rem;
    line-height: ${props => props.height}rem;
    font-size: ${props => props.fontSize}rem;
    padding: ${props => props.padding};
    box-sizing: content-box;
    width: ${props => (props.block ? '100%' : 'auto')};
    border: ${props => props.border};
    &:disabled {
      opacity: 0.2;
      cursor: default;
    }
  }
`;

export const ButtonLink = props => {
  const {
    children,
    className,
    backgroundColor,
    color,
    height,
    borderRadius,
    fontSize,
    border,
    padding,
  } = props;
  return (
    <ButtonLinkStyled
      backgroundColor={backgroundColor}
      color={color}
      height={height}
      borderRadius={borderRadius}
      fontSize={fontSize}
      padding={padding}
      border={border}
      className={className}
    >
      {children}
    </ButtonLinkStyled>
  );
};

ButtonLink.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  borderRadius: PropTypes.number,
  fontSize: PropTypes.number,
  externalLink: PropTypes.bool,
  border: PropTypes.string,
  padding: PropTypes.string,
};

ButtonLink.defaultProps = {
  backgroundColor: colors.blue,
  color: colors.white,
  height: 3.6,
  borderRadius: 3,
  fontSize: 1.4,
  externalLink: false,
  border: '',
  padding: '0 2.5rem',
};
