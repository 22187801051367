import { Auth } from 'aws-amplify';

async function signIn(email, password) {
  const credentials = await Auth.signIn(email, password);
  return credentials;
}

async function signOut() {
  const res = await Auth.signOut();
  return res;
}

async function getCurrentSession() {
  const currentSession = await Auth.currentSession();
  return currentSession;
}

async function getCurrentCredentials() {
  const currentCredentials = await Auth.currentCredentials();
  return currentCredentials;
}

export { signIn, signOut, getCurrentSession, getCurrentCredentials };
