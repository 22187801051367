import { get } from 'lodash';
import {
  signIn,
  signOut,
  getCurrentSession,
  getCurrentCredentials,
} from '../../Services/auth';
import { getAvatar } from '../../Services/file';
import { push } from 'connected-react-router';
import {
  POST_MESSAGE_TYPE,
  LOCAL_STORAGE_SELF_LOGGED_IN,
  CAN_KEYS_PUBLIC_KEYS,
} from 'Utils/constants';
import { rsa512Encrypt } from 'Utils/app';
import { sendMessageToCB } from 'Utils/postMessage';
import avatar from 'Assets/Images/ic-none-profile.svg';

const credentialsInit = {
  userAttributes: { imageUrl: null },
  currentSession: null,
  currentCredentials: null,
  // isFetchingCredentials: true,
};
export const credentials = {
  state: {
    ...credentialsInit,
  }, // initial state
  reducers: {
    login(state, payload) {
      return {
        ...state,
        userAttributes: payload.attributes,
        currentSession: payload.signInUserSession,
      };
    },
    logOut(state) {
      return {
        ...state,
        ...credentialsInit,
        isFetchingCredentials: false,
      };
    },
    setCurrentSession(state, payload) {
      return {
        ...state,
        currentSession: payload,
        userAttributes: payload.idToken.payload,
      };
    },
    setCurrentCredentials(state, payload) {
      return { ...state, currentCredentials: payload };
    },
    setFetchingCredentialsState(state, payload) {
      return { ...state, isFetchingCredentials: payload };
    },
    updateImageUrl(state, payload) {
      return {
        ...state,
        userAttributes: { ...payload, imageUrl: payload.imageUrl },
      };
    },
  },
  effects: dispatch => ({
    async loginAsync(payload, { transaction }) {
      try {
        const encryptedPw = await rsa512Encrypt(
          payload.password,
          CAN_KEYS_PUBLIC_KEYS,
        );

        const credentials = await signIn({
          username: payload.email,
          password: payload.password,
          validationData: {
            encryptedPw,
          },
        });
        dispatch.credentials.login(credentials);

        // update image URL form picture
        const picture =
          get(credentials, 'attributes.picture') &&
          JSON.parse(get(credentials, 'attributes.picture'));

        if (picture) {
          const imageUrl = await getAvatar(picture);
          dispatch.credentials.updateImageUrl({
            ...credentials.attributes,
            imageUrl: imageUrl,
          });
        } else {
          dispatch.credentials.updateImageUrl({
            ...credentials.attributes,
            imageUrl: avatar,
          });
        }

        localStorage.setItem(LOCAL_STORAGE_SELF_LOGGED_IN, true);

        const { redirectBack } = transaction;
        dispatch(push(redirectBack ? redirectBack : '/'));
      } catch (e) {
        dispatch.notification.openAsync(e.message);
      }
    },
    async logOutAsync() {
      try {
        await signOut();
        dispatch.credentials.logOut();
        dispatch.application.clearStore();
        dispatch.client.clearStore();
        localStorage.clear();
        // post logout message for crypto badge
        sendMessageToCB({ type: POST_MESSAGE_TYPE.LOGOUT });
        dispatch(push('/login'));
      } catch (e) {
        dispatch.notification.openAsync(e.message);
      }
    },
    async getCurrentSessionAsync() {
      try {
        const currentSession = await getCurrentSession();
        dispatch.credentials.setCurrentSession(currentSession);

        // update image URL form picture
        const userAttributes = currentSession.idToken.payload;
        const picture =
          userAttributes.picture && JSON.parse(userAttributes.picture);

        if (picture) {
          const imageUrl = await getAvatar(picture);
          dispatch.credentials.updateImageUrl({
            ...userAttributes,
            imageUrl: imageUrl,
          });
        }
        if (currentSession && window.location.pathname === '/login') {
          dispatch(push('/'));
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getCurrentCredentialsAsync() {
      try {
        const currentCredentials = await getCurrentCredentials();
        dispatch.credentials.setCurrentCredentials(currentCredentials);
      } catch (e) {
        console.log(e);
      }
    },
    async getCredentials() {
      try {
        const currentCredentials = this.getCurrentCredentialsAsync();
        const currentSession = this.getCurrentSessionAsync();

        await Promise.allSettled([currentSession, currentCredentials]);
        this.setFetchingCredentialsState(false);
      } catch (e) {
        console.log(e);
      }
    },
  }),

  selectors: slice => ({
    isLoggedInSelector() {
      return slice(state => !!state.currentSession);
    },
    // isGettingCredentialsSelector() {
    //   return slice(state => state.isFetchingCredentials);
    // },
    isGettingCredentialsSelector() {
      return slice(
        state =>
          state.loading.getCurrentSessionAsync &&
          state.loading.getCurrentCredentialsAsync,
      );
    },
    userAttributesSelector() {
      return slice(state => state.userAttributes);
    },
    accessTokenSelector() {
      return slice(state => get(state, 'currentSession.accessToken.jwtToken'));
    },
    canIdTokenSelector() {
      return slice(state => get(state, 'currentSession.idToken.jwtToken'));
    },
  }),
};
