import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './Containers/App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_cognito_identityPoolId, // REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_s3_region, // REQUIRED - Amazon Cognito Region
    userPoolId: process.env.REACT_APP_cognito_userPoolId, // OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: process.env.REACT_APP_cognito_userPoolWebClientId, // OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    level: 'private',
    identityPoolId: process.env.REACT_APP_cognito_identityPoolId, // REQUIRED - Amazon Cognito Identity Pool ID,
    bucket: process.env.REACT_APP_s3_bucket_image, // REQUIRED -  Amazon S3 bucket
    region: process.env.REACT_APP_s3_region, // OPTIONAL -  Amazon service region
  },
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
