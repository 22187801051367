import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import { Switch, Link } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { useIntl, defineMessages } from 'react-intl';
import { IntlContext } from 'Providers/IntlProvider';
// import styled from 'styled-components/macro';

// import { Spinner } from 'Components/Spinner';
// import { useSelect } from 'Hooks';

import Login from '../Login';
import SignUp from '../SignUp';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
import DefaultRoute from '../Layout/DefaultRoute';
import NonHeaderRoute from '../Layout/NonHeaderRoute';
import SidebarRoute from '../Layout/SidebarRoute';
import Oauth2AppCreate from '../Oauth2AppCreate';
import Oauth2AppEdit from '../Oauth2AppEdit';
import Oauth2Clients from '../Oauth2Clients';
import Oauth2ClientCreate from '../Oauth2ClientCreate';
import Oauth2ClientEdit from '../Oauth2ClientEdit';
import ErrorPage from '../ErrorPage';
import SignTransaction from 'Containers/SignTransaction';
import { history } from '../../Store';
import { Notification } from '../../Components/Notification';
// import { colors } from '../../Theme/constants';

const ROUTES = [
  {
    path: '/oauth2/app/create',
    component: Oauth2AppCreate,
  },
];

const ROUTES_SIDEBAR = [
  {
    path: '/',
    component: Oauth2AppEdit,
  },
  {
    path: '/oauth2/app',
    component: Oauth2AppEdit,
  },

  {
    path: '/oauth2/clients',
    component: Oauth2Clients,
  },
  {
    path: '/oauth2/clients/create',
    component: Oauth2ClientCreate,
  },
  {
    path: '/oauth2/clients/:id',
    component: Oauth2ClientEdit,
  },
];
const ROUTES_NONE_HEADER = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/sign-up',
    component: SignUp,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '/sign-transaction',
    component: SignTransaction,
  },
  {
    path: '/404',
    component: ErrorPage,
  },
  {
    path: '*',
    component: ErrorPage,
  },
];

const { home, about } = defineMessages({
  home: {
    id: 'home',
    defaultMessage: 'home page',
  },
  about: {
    id: 'about',
    defaultMessage: 'about page',
  },
});

// const Loading = styled.div`
//   height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;

//   p {
//     font-size: 1.5rem;
//   }
// `;

function Routes() {
  const { formatMessage } = useIntl();
  const { changeLang } = useContext(IntlContext);
  const changeLanguage = lang => changeLang(lang);

  return (
    <React.Fragment>
      <Notification />

      <ConnectedRouter history={history}>
        <nav style={{ display: 'none' }}>
          <ul>
            <li>
              <Link to="/">{formatMessage(home)}</Link>
            </li>
            <li>
              <Link to="/about">{formatMessage(about)}</Link>
            </li>
          </ul>
          <button onClick={() => changeLanguage('en')}>
            Change to english language
          </button>
          <button onClick={() => changeLanguage('ko')}>
            Change to korean language
          </button>
        </nav>
        <Switch>
          {ROUTES.map(route => (
            <DefaultRoute
              key={route.path}
              exact
              path={route.path}
              component={route.component}
            />
          ))}
          {ROUTES_SIDEBAR.map(route => (
            <SidebarRoute
              key={route.path}
              exact
              path={route.path}
              component={route.component}
            />
          ))}

          {ROUTES_NONE_HEADER.map(route => (
            <NonHeaderRoute
              key={route.path}
              exact
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </ConnectedRouter>
    </React.Fragment>
  );
}

Routes.propTypes = {};

export default Routes;
