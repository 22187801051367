export const notification = {
  state: {
    isOpen: false,
    message: null,
  },
  reducers: {
    open(state, payload) {
      return { ...state, isOpen: true, message: payload };
    },
    close(state) {
      return { ...state, isOpen: false, message: null };
    },
  },
  effects: dispatch => ({
    async openAsync(message) {
      dispatch.notification.open(message ? message : 'Error');
    },
    async closeAsync() {
      dispatch.notification.close();
    },
  }),
  selectors: slice => ({
    message() {
      return slice(state => state.message);
    },
    isOpen() {
      return slice(state => state.isOpen);
    },
  }),
};
