import store from 'Store';

const graphqlEndpoint =
  process.env.REACT_APP_graphqlEndpoint ||
  'https://dev.api.cryptobadge.app/can-keys-test/graphql';

export const graphql = body =>
  new Promise((resolve, reject) => {
    const canIdToken = store.select.credentials.canIdTokenSelector(
      store.getState(),
    );
    return fetch(graphqlEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Can-Id-Token': canIdToken,
      },
      body: JSON.stringify({
        query: body.query,
        variables: body.variables,
      }),
    })
      .then(res => res.json())
      .then(response => {
        if (response.errors) {
          reject(response.errors);
        } else {
          resolve(response.data);
        }
      });
  });

export const getTx = txId => {
  return graphql({
    query: `
      query node($id: ID!) {
        RequestSignTransaction:node (id: $id) {
          ... on RequestSignTransaction {
          transaction {
            actions {
              account
              name
              authorization {
                actor
                permission
              }
              data
            }
          }
            serviceId
            broadcast
          }
        }
      }
    `,
    variables: {
      id: window.btoa('RequestSignTransaction:' + txId),
    },
  }).then(data => data.RequestSignTransaction);
};

export const signTx = (txId, secretCode, broadcast) => {
  return graphql({
    query: `
      mutation signTransaction($input: SignTransactionInput!) {
        signTransaction (input: $input) {
          transactionId
          signatures
          serializedTransaction
        }
      }
    `,
    variables: {
      input: {
        userPw: secretCode,
        requestId: txId,
        expireSeconds: 60, // fix deadline exceeded error
        broadcast: 'boolean' === typeof broadcast ? broadcast : true,
      },
    },
  }).then(data => data.signTransaction);
};

export const getSession = () => {
  return graphql({
    query: `
      query hasSession {
        hasSession
      }
    `,
  }).then(data => data.hasSession);
};
