import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import navIcon from 'Images/open.svg';
import iconCredential from 'Assets/Images/ic-credential.svg';
import opacity from '../../Utils/opacity';
import { colors } from '../../Theme/constants';
import { withRouter } from 'react-router';

const SidebarStyled = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: #fafafa;
`;

const Head = styled.div`
  padding: 1.2rem 1.5rem;
  border-bottom: 0.1rem solid #e5e5e5;
  display: flex;
  align-items: center;
`;

const MenuStyled = styled.ul`
  li {
    a {
      color: ${opacity(colors.black, 95)};
      display: flex;
      align-items: center;
      height: 4rem;
      width: 100%;
      padding: 1.2rem 1.5rem;
      img {
        object-fit: contain;
        width: 1.6rem;
        margin-right: 0.8rem;
      }
      &.active {
        background-color: ${opacity(colors.blue, 5)};
        color: ${colors.blue};
      }
      &:hover {
        background-color: ${opacity(colors.black, 5)};
      }
    }
  }
`;

const menuItemMap = [
  {
    link: '/oauth2/clients',
    icon: iconCredential,
    name: 'Credentials',
  },
  {
    link: '/oauth2/app',
    icon: navIcon,
    name: 'OAuth consent screen',
  },
];

const Sidebar = props => {
  const { className, location } = props;
  return (
    <React.Fragment>
      <SidebarStyled className={className}>
        <Head className="cp-head">
          <h5>Apis and Services</h5>
        </Head>
        <MenuStyled>
          {menuItemMap.map(item => {
            return (
              <li key={item.link}>
                <NavLink to={item.link + location.search} title={item.name}>
                  <img src={item.icon} alt={item.name} />
                  <span>{item.name}</span>
                </NavLink>
              </li>
            );
          })}
        </MenuStyled>
      </SidebarStyled>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
};

export default withRouter(Sidebar);
