import store from 'Store';
import {
  CRYPTO_BADGE_URL,
  POST_MESSAGE_TYPE,
  LOCAL_STORAGE_SELF_LOGGED_IN,
} from 'Utils/constants';

import { isTestingEnv } from 'Utils/app';

export const cryptobadgeIframeId = 'cbiframe';

export const sendMessageToCB = ({ type, data = {} }) => {
  const iframe = document.getElementById(cryptobadgeIframeId);
  if (iframe && iframe.contentWindow) {
    iframe.contentWindow.postMessage(
      {
        type,
        message: 'message',
        body: data,
      },
      isTestingEnv ? '*' : CRYPTO_BADGE_URL,
    );
  }
};

export const receiveMessage = (event = {}) => {
  const {
    data,
    data: { type, body: dataMessage },
    origin,
  } = event;

  const isLoggedIn = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_SELF_LOGGED_IN),
  );

  if (data && origin) {
    if (origin !== CRYPTO_BADGE_URL && !isTestingEnv) return;
    switch (type) {
      case POST_MESSAGE_TYPE.LOGOUT:
        if (!isLoggedIn) {
          const { pathname, search } = window.location;
          store.dispatch.transaction.setRedirectBack(pathname + search);
          store.dispatch.credentials.logOutAsync();
        }
        break;
      case POST_MESSAGE_TYPE.LOGIN:
        if (dataMessage) {
          localStorage.removeItem(LOCAL_STORAGE_SELF_LOGGED_IN);
          const storage = JSON.parse(dataMessage);
          for (let [key, value] of Object.entries(storage)) {
            value = value.replace(/^(")|(")$/g, ''); // remove " character from value
            localStorage.setItem(key, value);
          }
          store.dispatch.credentials.getCredentials();
        }
        break;
      default:
        break;
    }
  }
};
