import Amplify from 'aws-amplify';

async function getAvatar(picture) {
  if (!picture.key) return '';
  try {
    const link = await Amplify.Storage.get(picture.key, {
      level: 'protected',
      ...picture,
    });
    if (link) {
      const endOfLink = link.indexOf('?');
      return link
        .substring(0, endOfLink)
        .replace(
          'https://crypto-badge-static-prod.s3.us-west-2.amazonaws.com',
          'https://d804z73gtqcb.cloudfront.net',
        );
    }
  } catch (e) {
    /* eslint-disable-next-line */
    console.log('---- error', e);
  }
  return '';
}

export { getAvatar };
